
import { mapGetters } from 'vuex'

import Breadcrumbs from '@/components/Breadcrumbs'
import Delivery from '@/components/order/Delivery'
import Payment from '@/components/order/Payment'
import Installment from '@/components/order/Installment'

export default {
  components: {
    Breadcrumbs,
    Delivery,
    Payment,
    Installment
  },

  middleware ({ store, redirect, app }) {
    if (!store.state.order.order.id) {
      return redirect(app.localePath({ name: 'index' }))
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.thanks.title'),
          to: '#',
          active: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      callcenter: 'callcenter',
      order: 'order/order',
      logo: 'logo',
      settings: 'settings'
    }),

    items () {
      return this.order.deliveries.reduce((items, delivery) => items.concat(delivery.items), [])
    },

    total () {
      return this.items.reduce((total, item) => {
        return total + item.quantity * (item.store.price.current + item.warranties.reduce((total, item) => total + item.price, 0) + item.addons.reduce((total, item) => total + item.price, 0))
      }, 0)
    },

    totalDelivery () {
      return this.order.deliveries.reduce((total, delivery) => total + delivery.price, 0)
    },

    totalDiscount () {
      return this.items.reduce((total, item) => {
        const price = item.store.price.current + item.warranties.reduce((total, item) => total + item.price, 0) + item.addons.reduce((total, item) => total + item.price, 0)

        let discount = 0

        if (item.coupon && item.coupon.discount.type.type === 'percent') {
          discount = Math.round(price * item.coupon.discount.percent / 100)
        } else if (item.coupon && item.coupon.discount.type.type === 'amount') {
          discount = item.coupon.discount.amount.amount
        }

        return total + item.quantity * discount
      }, 0)
    },

    totalCommission () {
      return this.order.payments.reduce((total, payment) => total + payment.commission, 0)
    },

    grandTotal () {
      return this.total + (this.settings.delivery.include ? this.totalDelivery : 0) + this.totalCommission - this.totalDiscount
    },

    operating () {
      const schedule = this.callcenter.schedule.days
      const timeFormat = 'hh:mm'
      const operating = {
        message: '',
        class: ''
      }

      if (Array.isArray(schedule)) {
        const moment = this.$moment().locale(this.$i18n.locale)
        const weekday = moment.isoWeekday()
        const open = this.$moment(schedule[weekday - 1].open, timeFormat)
        const close = this.$moment(schedule[weekday - 1].close, timeFormat)

        if (moment.isBefore(open)) {
          operating.message = this.$t('pages.thanks.callback.true.today.notYet', {
            open: schedule[weekday - 1].open
          })
          operating.class = 'is-warning'
        } else if (moment.isBetween(open, close)) {
          operating.message = this.$t('pages.thanks.callback.true.today.operating')
          operating.class = 'is-success'
        } else if (schedule[weekday !== 7 ? weekday : 0].open) {
          operating.message = this.$t('pages.thanks.callback.true.tomorrow', {
            open: schedule[weekday !== 7 ? weekday : 0].open
          })
          operating.class = 'is-warning'
        } else {
          operating.message = this.$t('pages.thanks.callback.true.sometime')
          operating.class = 'is-warning'
        }
      } else if (schedule === '') {
        operating.message = this.$t('pages.thanks.callback.true.today.operating')
        operating.class = 'is-success'
      }

      return operating
    }
  },

  mounted () {
    this.$store.dispatch('cart/setCart', { items: [] })
  },

  head () {
    return {
      title: this.$t('pages.thanks.title'),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: this.$config.appUrl + this.$route.path
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.thanks.description', { order: this.order.id })
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('pages.thanks.title')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('pages.thanks.description', { order: this.order.id })
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        // },
        // {
        //   hid: 'keywords',
        //   name: 'keywords',
        //   content: ''
        }
      ]
    }
  }
}
