
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

import SideMenu from '@/components/user/SideMenu'
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  components: {
    SideMenu,
    Breadcrumbs
  },

  mixins: [
    clickaway
  ],

  middleware: 'auth',

  async asyncData ({ params, $axios, app }) {
    const preferences = await $axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/user/preferences`)
      .catch((error) => {
        console.log(error)
      })

    return {
      preferences
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.user.preferences.title'),
          to: '#',
          active: true
        }
      ],
      form: {
        locale: {
          isActiveDropdown: false,
          updated: false
        },
        preferences: {
          columns: 3
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      locales: 'locales'
    })
  },

  methods: {
    hideLocaleDropDown () {
      this.form.locale.isActiveDropdown = false
    },

    toggleLocaleSelect () {
      this.form.locale.isActiveDropdown = !this.form.locale.isActiveDropdown
    },

    changeLocale (locale) {
      const updatedUser = { ...this.$auth.user }
      updatedUser.locale = locale
      this.$auth.setUser(updatedUser)

      this.hideLocaleDropDown()

      this.form.locale.updated = this.$auth.user.locale.code !== this.$i18n.locale
    }
  },

  head () {
    return {
      title: this.$t('pages.user.preferences.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.user.preferences.description')
        }
      ]
    }
  }
}
